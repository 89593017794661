<template>
  <div>
    <a-card title="产品定价">
      <a-button
        slot="extra"
        type="primary"
        style="margin-right: 8px"
        ghost
        v-print="'#printContent'"
      >
        <a-icon type="printer" />打印</a-button
      >
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section id="printContent">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="产品编号">
              {{ goods.number }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
              {{ goods.name }}
            </a-descriptions-item>
            <a-descriptions-item label="cs名称">
              {{ goods.cs_id }}
            </a-descriptions-item>
            <a-descriptions-item label="工厂成本(元)">
              {{ goods.good_cost.toFixed(2) }}
            </a-descriptions-item>
            <a-descriptions-item label="pic">
              {{ goods.pic }}
            </a-descriptions-item>
            <a-descriptions-item label="容量">
              {{ goods.mah }}mah
            </a-descriptions-item>
          </a-descriptions>

          <a-divider orientation="left" style="margin-top: 30px"
            >Amazon信息(美元计价)</a-divider
          >
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-row>
              <a-col :span="6" style="width: 350px">
                <a-form-model-item prop="salePriceUSD" label="选择asin">
                  <a-select v-model="salePriceUSD"  @change="handleAsinChange" style="width: 100%">
                    <a-select-option
                      v-for="item in asinItems"
                      :key="item.price"
                      :value="item"
                    >
                    {{ item.store_name }} {{ item.asin }}:{{ item.price }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="salePriceUSD" label="Amazon 售价">
                  <!-- <a-input v-model="salePriceUSD" /> -->
                  <a-input-number
                    v-model="salePriceUSD"
                    style="width: calc(100% - 20px)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="exchangeRate" label="汇率">
                  <a-input-number
                    v-model="exchangeRate"
                    style="width: calc(100% - 20px)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="commission" label="佣金">
                  <a-input :value="commission" :disabled="true" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="deliveryFeeUSD" label="配送费">
                  <a-input v-model="deliveryFeeUSD" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="firstDeliveryUSD" label="头程">
                  <a-input v-model="firstDeliveryUSD" />
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="profit_temp" label="未除成本利润">
                  <a-input :value="profit_temp" :disabled="true" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>

          <a-divider orientation="left" style="margin-top: 30px"
            >参考信息</a-divider
          >
          <div>
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="ref_columns"
                :data-source="tableData"
                :pagination="false"
              >
              </a-table>
            </div>
          </div>

          <a-divider orientation="left" style="margin-top: 30px"
            >定价信息</a-divider
          >
          <div style="margin-top: 5px">
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="goods_price_columns"
                :data-source="goods_price_level"
                :pagination="false"
              >
                <div slot="profitRate" slot-scope="value, item, index">
                  <a-input-number
                    v-model="item.profitRate"
                    :min="0"
                    :formatter="(value) => (value !== 0 ? `${value}%` : '')"
                    :parser="(value) => value.replace('%', '')"
                    @change="() => updateProfit(item)"
                    size="small"
                  ></a-input-number>
                </div>
                <div slot="price" slot-scope="value, item, index">
                  <a-input-number
                    v-model="item.price"
                    :min="0"
                    @change="() => updateProfitAndMargin(item)"
                    size="small"
                  ></a-input-number>
                </div>

                <div slot="action" slot-scope="value, item">
                    <a-button
                      size="small"
                      type="primary"
                      @click="set_goods_price(item)"
                      >保存</a-button
                    >
                </div>
              </a-table>
            </div>
          </div>
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { goodsInformationRetrieve,goodsInformationUpdate,asinInfo } from "@/api/goods";
export default {
  data() {
    return {
      goods: {},
      loading: false,
      pic:1,
      asinItems: [],
      ref_columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 60,
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "Amazon利润率",
          dataIndex: "profitRate",
          key: "profitRate",
        },
        {
          title: "Amazon利润",
          dataIndex: "saleProfit",
          key: "saleProfit",
        },
        {
          title: "工厂定价(人民币)",
          dataIndex: "factoryProfitPrice",
          key: "factoryProfitPrice",
        },
        {
          title: "工厂定价(美元)",
          dataIndex: "factoryProfitPriceUSD",
          key: "factoryProfitPriceUSD",
        },
        {
          title: "工厂净利润(人民币)",
          dataIndex: "factoryNetProfit",
          key: "factoryNetProfit",
        },
        {
          title: "工厂净利润(美元)",
          dataIndex: "factoryNetProfitUSD",
          key: "factoryNetProfitUSD",
        },
      ],
      goods_price_columns: [
        {
          title: "等级",
          dataIndex: "type",
          key: "type",
          width: 60,
        },
        {
          title: "Amazon利润率",
          dataIndex: "profitRate",
          key: "profitRate",
          scopedSlots: { customRender: "profitRate" },
        },
        {
          title: "Amazon利润",
          dataIndex: "saleProfit",
          key: "saleProfit",
        },
        {
          title: "工厂定价(人民币)",
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price" },
        },
        {
          title: "工厂定价(美元)",
          dataIndex: "factoryProfitPriceUSD",
          key: "factoryProfitPriceUSD",
        },
        {
          title: "工厂净利润(人民币)",
          dataIndex: "factoryNetProfit",
          key: "factoryNetProfit",
        },
        {
          title: "工厂净利润(美元)",
          dataIndex: "factoryNetProfitUSD",
          key: "factoryNetProfitUSD",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      goods_price_level: [],
      //
      deliveryFeeUSD: 0,
      firstDeliveryUSD: 0,

      salePriceUSD: 0, // 美元销售价格
      factoryCostRMB: 0, // 人民币工厂成本
      exchangeRate: 7.2, // 汇率（美元兑人民币）
      profitRates: [15, 20, 22, 25, 30, 35, 40], // 利润率数组
    };
  },
  computed: {
    commission() {
      return (this.salePriceUSD * 0.15).toFixed(2); // 假设佣金为15%
    },
    profit_temp() {
      return (
        this.salePriceUSD * 0.85 -
        this.deliveryFeeUSD -
        this.firstDeliveryUSD
      ).toFixed(2);
    },
    tableData() {
      return this.profitRates.map((rate) => {
        const saleProfit = (this.salePriceUSD * (rate / 100)).toFixed(2); // 销售利润
        const factoryProfitPriceUSD = (this.profit_temp - saleProfit).toFixed(
          2
        ); //产品定价
        const factoryProfitPrice = (
          factoryProfitPriceUSD * this.exchangeRate
        ).toFixed(2); // 产品定价美元
        const factoryNetProfit = (
          factoryProfitPrice - this.factoryCostRMB
        ).toFixed(2); // 工厂净利润
        const factoryNetProfitUSD = (
          factoryNetProfit / this.exchangeRate
        ).toFixed(2); // 工厂净利润
        return {
          profitRate: rate + "%",
          saleProfit,
          factoryProfitPrice,
          factoryNetProfit,
          factoryProfitPriceUSD,
          factoryNetProfitUSD,
        };
      });
    },
  },

  methods: {
    handleAsinChange(item) {
      this.salePriceUSD = item.price
    },

    async get_goods_info(good_id) {
      this.loading = true;
      try {
        const res = await goodsInformationRetrieve({ id: good_id });
        this.goods = res;
        this.pic = res.pic
        this.factoryCostRMB = res.good_cost
        this.goods_price_level = [
          {
            price: res.retail_price,
            type: "零售价",
            code: "retail_price",
          },
          {
            price: res.level_price1,
            type: "等级一",
            code: "level_price1",
          },
          {
            price: res.level_price2,
            type: "等级二",
            code: "level_price2",
          },
          {
            price: res.level_price3,
            type: "等级三",
            code: "level_price3",
          },
        ];
        this.get_asin_info(res.cs_id);
       
      } catch (error) {
        console.error("Failed to retrieve goods information:", error);
        this.$message.error("获取商品信息失败");
      } finally {
        this.loading = false;
      }
    },

    async get_asin_info(cs_id) {
        if(!cs_id) {
          this.$message.warning("此产品的cs_id为空");
          return;
        }
        const res = await asinInfo({ cs_id: cs_id });
        if(res.asin_info.length === 0) {
          this.$message.warning("此产品的asin信息为空,默认售价设为50");
          this.salePriceUSD = 50
        }else{
            this.asinItems = res.asin_info;
            this.salePriceUSD = this.asinItems[0]['price']
        }
        if(res.ship_info.length === 0){
            this.$message.warning("此产品的运费信息为空,默认设为10");
            this.deliveryFeeUSD = 10
            this.firstDeliveryUSD = 10
        }else{
            this.deliveryFeeUSD = res.ship_info[0]['deliver_price']
            this.firstDeliveryUSD = res.ship_info[0]['ship_price']
        }
        this.init_goods_profit();
    },

    updateProfitAndMargin(item) {
      const index = this.goods_price_level.indexOf(item);
      if (item.price) {
        let saleProfit = (
          this.profit_temp -
          item.price / this.exchangeRate
        ).toFixed(2);
        let profitRate = ((saleProfit / this.salePriceUSD) * 100).toFixed(2);
        let factoryNetProfit = (item.price - this.factoryCostRMB).toFixed(2);
        let factoryNetProfitUSD = (
          factoryNetProfit / this.exchangeRate
        ).toFixed(2);
        let factoryProfitPriceUSD = (item.price / this.exchangeRate).toFixed(2);
        this.$set(this.goods_price_level[index], "saleProfit", saleProfit);
        this.$set(this.goods_price_level[index], "profitRate", profitRate);
        this.$set(
          this.goods_price_level[index],
          "factoryNetProfit",
          factoryNetProfit
        );
        this.$set(
          this.goods_price_level[index],
          "factoryProfitPriceUSD",
          factoryProfitPriceUSD
        );
        this.$set(
          this.goods_price_level[index],
          "factoryNetProfitUSD",
          factoryNetProfitUSD
        );
      } else {
        this.$set(this.goods_price_level[index], "saleProfit", 0);
        this.$set(this.goods_price_level[index], "profitRate", 0);
        this.$set(this.goods_price_level[index], "factoryNetProfitUSD", 0);
        this.$set(this.goods_price_level[index], "factoryNetProfit", 0);
        this.$set(this.goods_price_level[index], "factoryProfitPriceUSD", 0);
      }
    },
    updateProfit(item_) {
      const index = this.goods_price_level.indexOf(item_);
      const item = this.goods_price_level[index];
      const rate = item.profitRate;
      if (rate) {
        const saleProfit = (this.salePriceUSD * (rate / 100)).toFixed(2); // 销售利润
        const factoryProfitPriceUSD = (this.profit_temp - saleProfit).toFixed(
          2
        ); //产品定价
        const factoryProfitPrice = (
          factoryProfitPriceUSD * this.exchangeRate
        ).toFixed(2); // 产品定价美元
        const factoryGrossProfit = factoryProfitPriceUSD * this.exchangeRate; // 工厂利润未除成本
        const factoryNetProfit = (
          factoryGrossProfit - this.factoryCostRMB
        ).toFixed(2); // 工厂净利润
        const factoryNetProfitUSD = (
          factoryNetProfit / this.exchangeRate
        ).toFixed(2); // 工厂净利润

        this.$set(this.goods_price_level[index], "saleProfit", saleProfit);
        this.$set(
          this.goods_price_level[index],
          "factoryNetProfit",
          factoryNetProfit
        );
        this.$set(
          this.goods_price_level[index],
          "factoryProfitPriceUSD",
          factoryProfitPriceUSD
        );
        this.$set(
          this.goods_price_level[index],
          "factoryNetProfitUSD",
          factoryNetProfitUSD
        );
        this.$set(this.goods_price_level[index], "price", factoryProfitPrice);
      } else {
        this.$set(this.goods_price_level[index], "saleProfit", 0);
        this.$set(this.goods_price_level[index], "profitRate", 0);
        this.$set(this.goods_price_level[index], "factoryNetProfitUSD", 0);
        this.$set(this.goods_price_level[index], "factoryNetProfit", 0);
        this.$set(this.goods_price_level[index], "factoryProfitPriceUSD", 0);
      }
    },

    init_goods_profit() {
      this.goods_price_level.forEach((item) => {
        this.updateProfitAndMargin(item);
      });
    },

    set_goods_price(item) {
      console.log("保存产品价格");
      console.log("item", item);
      this.goods[item.code] = item.price;
      this.loading = true;
      goodsInformationUpdate(this.goods).then(data => {
              this.$message.success('修改成功');
            }).finally(() => {
              this.loading = false;
            });
    },
  },

  watch:{
    salePriceUSD(newVal, oldVal) {
      this.init_goods_profit();
    },
    exchangeRate(newVal, oldVal) {
      this.init_goods_profit();
    },
    deliveryFeeUSD(newVal, oldVal) {
      this.init_goods_profit();
    },
    firstDeliveryUSD(newVal, oldVal) {
      this.init_goods_profit();
    },
  },

  mounted() {
    var good_id = this.$route.query.goods;
    this.get_goods_info(good_id);
  },
};
</script>

<style>
</style>